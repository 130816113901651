import { useState, useEffect, useCallback } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import {
  Container,
  Box,
  Typography,
  TextField,
  InputLabel,
  Grid,
  Button,
  FormControl,
  Alert,
  Snackbar,
} from "@mui/material";
import Navbar from "../../components/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import firebase from "../../firebase";

const styles = {
  input: {
    fontSize: 36,
    padding: 3,
    margin: 1,
  },
};

const defaultValues = {
  email: "",
  password: "",
};

const SignInPage = () => {
  const { control, handleSubmit } = useForm({ defaultValues });
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const navigation = useNavigate();

  const onSubmit = useCallback(async (data) => {
    const { email, password } = data;
    try {
      const res = await firebase.auth.signInWithEmailAndPassword(
        email,
        password
      );

      if (res?.user.uid) {
        localStorage.setItem("uid", res.user.uid);
        navigation("/administration/bathrooms");
      }
    } catch (error) {
      setOpen(true);
      setError(error.message);
    }
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <Container style={{ minWidth: '100%', padding: 0}}>
      <Navbar />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6} sm={6} lg={6}>
          <Box className="auth-form">
            <Box className="auth-box">
              <Typography className="text-center" align="center" variant="h2">
                Sign In
              </Typography>

              <form
                className="form"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                style={{textAlign: 'center'}}
              >
                <Controller
                  className="row"
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      sx={styles}
                      label="Email"
                      type="text"
                      variant="filled"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{ required: "Email is required" }}
                />

                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      sx={styles}
                      label="Password"
                      type="password"
                      variant="filled"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{ required: "Password is required" }}
                />

                <Box className="row">
                  <Button type="submit" style={{ background: " #1D95C9", color: "white" }}>
                    Sign In
                  </Button>
                  {/* <Box>
                    <Link
                      style={{
                        margin: "0 8px",
                        textDecoration: "underline",
                      }}
                      to="/signUp"
                    >
                      You do not have an account?
                    </Link>
                  </Box> */}
                </Box>

                <Snackbar open={open} autoHideDuration={6000}>
                  <Alert severity="error">{error}</Alert>
                </Snackbar>
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SignInPage;
