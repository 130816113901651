import { useEffect } from "react";
import Aos from "aos"
import "aos/dist/aos.css"

import Box from '@mui/material/Box';

import About from "../../components/AboutUs";
import Navbar from "../../components/Navbar";

import cardBackground from "../../assets/card3.png";

// import "../../styles/about.css";

const AboutUs = () => {

    useEffect(() => {
        Aos.init()
    }, [])

    return (
        <div
            data-aos="fade-up"
            data-aos-offset="100">
            <Navbar />
            <Box
                className="about"
                sx={{
                    position: "relative",
                    top: "160px",
                margin: "0px auto",
                background: "white",
                padding: 10,
                borderRadius: "20px",
                width: "80%",
                textAlign: "center"
            }}>
                <About />
            </Box>
        </div>
    )
}

export default AboutUs;