import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Pagination from '@mui/material/Pagination';

import firebase from "../../firebase";
import BathroomsService from "../../services/bathroomsService";

const Dashboard = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            const dbProducts = await (await firebase.firestore
                .collection('bathrooms').get()).docs.map((product => {
                    return { id: product.id, ...product.data() }
                }))
            return dbProducts
        }

        fetchImages()
            .then((res) => {
                console.log('res', res)
                setImages(res)
            })
    }, [])

    const remove = async (id) => {
        await BathroomsService.remove(id);
        const filtered = images.filter((x) => x.id !== id);
        setImages(filtered)
    }

    return (
        <div>
            <h2>Admin Panel</h2>

            <Link to="/administration/bathroom/new">
                <Button variant="contained">Add Bathroom</Button>
            </Link>

            <Grid container spacing={2} style={{ marginTop: 10 }}>
                {images.map((img) => (
                    <Grid key={img.id} item xs={12} sm={6} md={4} lg={3}>
                        <Card style={{ margin: 8 }}>
                            <CardMedia
                                component="img"
                                height="194"
                                image={img.imageUrl}
                            />
                            <Button
                                style={{ width: '100%', margin: '5px' }}
                                variant="contained"
                                onClick={() => remove(img.id)}
                                color="error"
                            >
                                Delete
                            </Button>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

export default Dashboard;
