import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
import { useParams } from "react-router-dom";
import Aos from "aos"
import "aos/dist/aos.css"

import BathroomsService from "../../services/bathroomsService";

const Search = () => {
    const { id } = useParams();
    const [items, setItems] = useState([])

    useEffect(() => {
        const category = id.split("&").join(" ").trim()
        console.log(category)
        BathroomsService.getByCategoryName(category)
            .then((res) => {
                setItems(res)
            })

        Aos.init()

    }, [])

    return (
        <div
            data-aos="fade-up"
            data-aos-offset="100"
        >
            <Navbar />
            <Box
                className="about"
                sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    margin: "30px auto"
                }}>
                {items && items.map((img) => {
                    return (
                        <Card key={img.imageUrl} 
                            style={{
                                border: "1px solid black",
                                padding: 6,
                                width: 300,
                                minHeight: 280
                                }}>
                            <CardMedia
                                component="img"
                                style={{height: "100%"}}
                                image={img.imageUrl}
                                alt="card search bathroom image"
                            />
                        </Card>
                    )
                })}
            </Box>
        </div>
    )
}

export default Search