import React, { useState, useEffect } from "react";
import {
    Typography,
    Box,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import ImageUpload from "../../../components/ImageUpload";
import firebase from "firebase";
import { useNavigate } from "react-router-dom";

const Add = () => {
    const [image, setImage] = useState(undefined);
    const [category, setCategory] = useState("");
    const [categories, setCategories] = useState([]);
    const [percent, setPercent] = useState(0);
    const navigation = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        firebase
            .firestore()
            .collection("categories")
            .get()
            .then((snapshot) => snapshot.docs.map((doc) => doc.data()))
            .then((r) => {
                setCategories(r);
            });
    }, []);

    const uploadImage = async () => {
        if (image && category) {
            try {
                const storageRef = firebase.storage().ref(`${image.name}`);
                const task = storageRef.put(image);

                task.on(
                    "state_changed",
                    (snap) => {
                        let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
                        setPercent(percentage);
                    },
                    (err) => {
                        console.error(err);
                    },
                    async () => {
                        const url = await storageRef.getDownloadURL();
                        const categoryRes = await firebase
                            .firestore()
                            .collection("categories")
                            .where("category", "==", category)
                            .get();

                        if (categoryRes.empty) {
                            await firebase.firestore().collection("categories").add({
                                category,
                            });
                        }

                        const response = await firebase.firestore().collection("bathrooms").add({
                            imageUrl: url,
                            category: category,
                        });

                        if (response) {
                            navigation("/administration/bathrooms");
                        }
                    }
                );
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <Box mt={10} ml={6} mr={6}>
            <Typography variant="h4">Upload image</Typography>
            <Box mt={3}>
                <Grid container spacing={2}>
                    <Grid item xs={2} md={1}>
                        <ImageUpload image={image} setImage={setImage} />
                        {percent > 0 && <p>Upload {percent}%...</p>}
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} lg={12}>
                        <FormControl fullWidth>
                            <InputLabel id="category-label">Image Category</InputLabel>
                            <Select
                                labelId="category-label"
                                id="category"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                <MenuItem value="">Select category</MenuItem>
                                {categories.map((c) => (
                                    <MenuItem key={c.category} value={c.category}>
                                        {c.category}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Box mt={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                            <Button onClick={uploadImage} variant="contained">
                                Add
                            </Button>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        </Box>
    );
};

export default Add;
