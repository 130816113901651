import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Drawer,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  AppBar,
  Toolbar,
  Box,
} from "@mui/material";
import AssessmentIcon from '@mui/icons-material/Assessment';
import BathroomIcon from '@mui/icons-material/Bathroom';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import MenuIcon from '@mui/icons-material/Menu';

import "../styles/admin.css";

const LeftAside = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      {isMobile ? (
        <>
          <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
            <Toolbar>
              <IconButton
                color="inherit"
                edge="start"
                onClick={toggleMenu}
                sx={{ marginRight: 2, display: { sm: 'block', md: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Admin Panel
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            anchor="left"
            open={menuOpen}
            onClose={toggleMenu}
          >
            <List sx={{marginTop: 8}}>
              <ListItem component={Link} to="/home">
                <AssessmentIcon />
                <Typography variant="body1" sx={{ marginLeft: 1 }}>Home Page</Typography>
              </ListItem>
              <ListItem button component={Link} to="/administration/bathrooms">
                <BathroomIcon />
                <Typography variant="body1" sx={{ marginLeft: 1 }}>Bathrooms</Typography>
              </ListItem>
              <ListItem button component={Link} to="/administration/videos">
                <VideoSettingsIcon />
                <Typography variant="body1" sx={{ marginLeft: 1 }}>Videos</Typography>
              </ListItem>
            </List>
          </Drawer>
        </>
      ) : (
        <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Admin Panel
            </Typography>
            <Box>
              <Button color="inherit" component={Link} to="/home">
                <AssessmentIcon />
                <span className="nav-text">Home Page</span>
              </Button>
              <Button color="inherit" component={Link} to="/administration/bathrooms">
                <BathroomIcon />
                <span className="nav-text">Bathrooms</span>
              </Button>
              <Button color="inherit" component={Link} to="/administration/videos">
                <VideoSettingsIcon />
                <span className="nav-text">Videos</span>
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default LeftAside;
