import * as firebase from "firebase"

const firebaseConfig = {
  apiKey: "AIzaSyBT6o9W7fghjhiOxjX2tZpyjinU7sCUK3M",
  authDomain: "bathroombuild-42777.firebaseapp.com",
  projectId: "bathroombuild-42777",
  storageBucket: "bathroombuild-42777.appspot.com",
  messagingSenderId: "1086904912490",
  appId: "1:1086904912490:web:16aa6bdc423134fbf7d813"
};

firebase.default.initializeApp(firebaseConfig);
const storage = firebase.default.storage();
const auth = firebase.default.auth()
const firestore = firebase.default.firestore()

export default {
  storage,
  auth,
  firestore
}