import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import BathroomIcon from '@mui/icons-material/Bathroom';
import CountertopsIcon from '@mui/icons-material/Countertops';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import BathroomWithTiles from '../videos/bathroom-with-tiles.mp4';
import Tiles from '../videos/tiles.mp4';
import BathroomWithSink from '../videos/bathroom-with-sink.mp4';
import BathroomToiletWithSink from '../videos/bathroom-toilet-with-sink.mp4';


import ServiceWorkCard from "./ServiceWorkCard";

const OurWork = ({ aos, offset }) => {
  return (
    <Container>
      <Typography align="center" variant="h3" >Our Services</Typography>

      <Container
      className="work-container"
      data-aos={aos}
      data-aos-offset={offset}
      maxWidth="md"
      style={{ display: 'flex', justifyContent: 'center', maxWidth: '100%' }}
    >
      <ServiceWorkCard CardIcon={BathroomIcon} content='Bathroom' />
      <ServiceWorkCard CardIcon={CountertopsIcon} content='Sinks' />
      <ServiceWorkCard CardIcon={AutoAwesomeMosaicIcon} content='Mosaic' />
    </Container>

    <Container sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
      <video controls style={{ width: '40%', height: '300px' }} src={BathroomWithTiles} type="video/mp4" />
      <video controls style={{ width: '40%', height: '300px' }} src={Tiles} type="video/mp4" />
      <video controls style={{ width: '40%', height: '300px' }} src={BathroomWithSink} type="video/mp4" />
      <video controls style={{ width: '40%', height: '300px' }} src={BathroomToiletWithSink} type="video/mp4" />
    </Container>
    </Container>
  );
};

export default OurWork;
