import Navbar from './Navbar'
import TitleCompany from './TitleCompany'
import Search from "./Search";

const Header = ({ aos, offset }) => {
  return (
    <header
      className="header"
      data-aos={aos}
      data-aos-offset={offset}
      style={{
        // position: 'relative',
        // top: 0,
      }}>
      <Navbar />
      <Search />
      <TitleCompany />
    </header>
  )
}

export default Header