import React, { useState } from 'react';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Grid,
  Paper,
} from '@mui/material';
import Navbar from '../../components/Navbar';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Тук добавете логика за изпращане на формата.
    console.log('Form Data:', formData);
  };

  return (
    <>
        <Navbar />
      <Box sx={{ mt: 8, textAlign: 'center' }}>
        <Typography variant="h4">Contact Us</Typography>
      </Box>

      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6">Send us a message:</Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                variant="outlined"
                required
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                required
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                label="Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                multiline
                rows={4}
                margin="normal"
                variant="outlined"
                required
                InputLabelProps={{ shrink: true }}
              />
              <Button type="submit" variant="contained" color="primary" sx={{ mt: 3 }}>
                Submit
              </Button>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6">Contact Information:</Typography>
            <Typography variant="body1">
              <strong>Email:</strong>stbathroombuild@hotmail.com
            </Typography>
            <Typography variant="body1">
              <strong>Phone:</strong>07400508258
            </Typography>
            <Typography variant="body1">
              <strong>Office Phone:</strong>01634 301314
            </Typography>
            <Typography variant="body1">
              <strong>Address:</strong>69 Palmerston road ME4 6NE CHATHAM KENT
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactForm;
