import firebase from "firebase";

const getAll = async () => {
    return await firebase.
        firestore().
        collection("videos").
        get().
        then((snapshot) => snapshot.docs.map(doc => {
            return { id: doc.id, ...doc.data() }
        }))
}

const remove = async (id) => {
    return await firebase.firestore().collection("videos").doc(id).delete()
}

export default {
    getAll,
    remove,
}