import firebase from "firebase";

const getAll = async (id) => {
    return await firebase.
        firestore().
        collection("categories").
        get().
        then((snapshot) => snapshot.docs.map(doc => doc.data()))  
}

export default {
    getAll
}