import { lazy, Suspense, useEffect } from "react";
import Aos from "aos"
import "aos/dist/aos.css"

import LinearProgress from "@mui/material/LinearProgress";

const Header = lazy(() => import("../../components/Header"));
const AboutUs = lazy(() => import("../../components/AboutUs"));

const HomePage = () => {
    useEffect(() => {
        Aos.init()
    }, [])

    return (
        <>
            <Suspense fallback={<LinearProgress />}>
                <Header aos="fade-up" offset="400" />
            </Suspense>

            <Suspense fallback={<LinearProgress />}>
                <AboutUs aos="fade-up" offset="200" />
            </Suspense>
        </>
    )
}

export default HomePage;