import React, { useState } from "react";
import {
  Button,
  Container,
  Typography,
  Paper,
  InputLabel,
  styled,
} from "@mui/material";
import firebase from 'firebase';
import { useNavigate } from "react-router-dom";

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(3),
}));

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const StyledFileInput = styled("input")({
  display: "none",
});

const VideoForm = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const navigation = useNavigate()

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    uploadVideoToFirebase(selectedFile)
    console.log("Добавен файл:", selectedFile);
    setSelectedFile(null);
  };

  const uploadVideoToFirebase = (videoFile) => {
    const storageRef = firebase.storage().ref(`videos/${videoFile.name}`);
    const task = storageRef.put(videoFile);
  
    task.on(
      "state_changed",
      (snapshot) => {
        const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (error) => {
        console.error("Error uploading video:", error);
      },
      () => {
        storageRef.getDownloadURL().then(async (url) => {
            const response = await firebase.firestore().collection("videos").add({
                url
            })

            if (response) {
                navigation("/administration/videos")
            }
        }).catch((error) => {
          console.error("Error getting video URL:", error);
        });
      }
    );
  };
  
  return (
    <StyledContainer component="main" maxWidth="xs" sx={{marginTop: 10}}>
      <StyledPaper elevation={3}>
        <Typography component="h1" variant="h5" align="center">
          Качване на видео
        </Typography>
        <StyledForm onSubmit={handleSubmit}>
          <InputLabel htmlFor="fileInput">Изберете видео файл</InputLabel>
          <StyledFileInput
            accept="video/*"
            id="fileInput"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="fileInput">
            <StyledButton
              fullWidth
              variant="outlined"
              component="span"
            >
              Изберете файл
            </StyledButton>
          </label>
          {selectedFile && (
            <Typography variant="body1">
              Избран файл: {selectedFile.name}
            </Typography>
          )}
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Качи видео
          </StyledButton>
        </StyledForm>
      </StyledPaper>
    </StyledContainer>
  );
};

export default VideoForm;
