import firebase from "firebase";

const remove = async (id) => {
    await firebase.firestore().collection("bathrooms").doc(id).delete()
}

const getByCategoryName = async(name) => {
    const bathrooms = await firebase.
        firestore().
        collection("bathrooms").
        where("category", "==", name).
        get().
        then((snapshot) => snapshot.docs.map(doc => doc.data()))

    return bathrooms;
}

export default {
    remove,
    getByCategoryName
}