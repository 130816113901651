import { useState, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

import Navbar from "../../components/Navbar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import firebase from "../../firebase";
import Videos from "../../components/Videos";

const GalleryPage = () => {
    const [loaded, setLoaded] = useState('Images');
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            const dbProducts = await (await firebase.firestore
                .collection('bathrooms').get()).docs.map((product => {
                    return { id: product.id, ...product.data() }
                }))
            return dbProducts
        }

        fetchImages()
            .then((res) => {
                console.log('res', res)
                setImages(res)
            })

        Aos.init();
    }, []);

    return (
        <div
            data-aos="fade-up"
            data-aos-offset="100">
            <Navbar />

            <div
                data-aos="fade-up"
                data-aos-offset="300"
                style={{ position: "relative", top: "80px" }}>
                <Typography align="center" sx={{ color: "orange" }} variant="h4">
                    Gallery
                </Typography>

                <Container style={{ maxWidth: '100%' }}>
                    <Container sx={{
                        margin: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'baseline',
                        width: '20%',
                    }}>
                        <Button onClick={() => setLoaded('Images')}>Images</Button>
                        <Button onClick={() => setLoaded('Videos')}>Videos</Button>
                    </Container>

                    {loaded === 'Images' ? (
                        <Grid container spacing={2}>
                            {images.map((img, i) => (
                                <Grid item key={i} xs={12} sm={6} md={4} lg={3}>
                                    <Card sx={{ height: "100%" }}>
                                        <CardMedia
                                            component="img"
                                            height="100%"
                                            image={img.imageUrl}
                                            alt="work"
                                        />
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Videos />
                    )}
                </Container>
            </div>
        </div>
    );
};

export default GalleryPage;
