import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Layout from "./pages/Layout";
import HomePage from "./pages/Home";
import AboutUsPage from "./pages/AboutUs";
import OurWorkPage from "./pages/OurWork";
import GalleryPage from "./pages/Gallery";
import Recommendations from "./pages/Recommendations";
import ContactUsPage from "./pages/ContactUs";
import SignInPage from "./pages/Auth/signIn";
import SignUpPage from "./pages/Auth/signUp";
import AdminPage from "./pages/Admin";
import Dashboard from "./pages/Admin/Dashboard";
import ListVideo from "./pages/Admin/ListVideo";
import NewBathroomPage from "./pages/Admin/Bathroom/Add";
import NewVideoPage from './pages/Admin/Video/Add';
import Search from "./pages/Search";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route exact path="" element={<HomePage />}/>
            <Route path="home" element={<HomePage />}/>
            <Route path="about" element={<AboutUsPage />}/>
            <Route path="work" element={<OurWorkPage />}/>
            <Route path="gallery" element={<GalleryPage />}/>
            <Route path="contact" element={<ContactUsPage />}/>
            <Route path="signIn" element={<SignInPage />}/>
            <Route path="signUp" element={<SignUpPage />}/>
            <Route path="bathrooms/type/:id" element={<Search />}/>
            <Route exact path="reviews" element={<Recommendations />}/>

            <Route path="administration" element={<AdminPage />}>
              <Route path="bathrooms" element={<Dashboard />}/>
              <Route path="videos" element={<ListVideo />}/>

              <Route path="bathroom" element={<Layout />}>
                <Route path="new" element={<NewBathroomPage />}/>
              </Route>

              <Route path="video" element={<Layout />}>
                <Route path="new" element={<NewVideoPage />}/>
              </Route>
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
