import firebase from "firebase";

const getAll = async () => {
    return await firebase.
        firestore().
        collection("reviews").
        get().
        then((snapshot) => snapshot.docs.map(doc => doc.data()))  
}

const add = async (username, email, subject, message) => {
    return await firebase.firestore().collection("reviews").add({ username, email, subject, message }).then((snapshot) => {
        return true;
    });
}

export default {
    getAll,
    add,
}