import { useEffect, lazy, Suspense } from 'react';
import { Typography, CircularProgress } from "@mui/material";
import Navbar from '../../components/Navbar';
import Reviews from '../../components/Reviews';
import Aos from "aos"
import "aos/dist/aos.css"

const RecommendationForm = lazy(() => import("../../components/RecommendationForm"))

const Recommendations = () => {

    useEffect(() => {
        Aos.init()
    }, [])

    return (
      <div
      data-aos="fade-up"
      data-aos-offset="100">
        <Navbar />

        <Suspense fallback={<CircularProgress />}>
            <Reviews />
        </Suspense>


        <Typography variant="h3" align="center" sx={{marginTop: 2}}>Leave us a review</Typography>
        <Suspense fallback={<CircularProgress />}>
            <RecommendationForm />
        </Suspense>
      </div>
    )
}

export default Recommendations;