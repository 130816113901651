import { useEffect } from "react";
import Aos from "aos"
import "aos/dist/aos.css"

import Box from "@mui/material/Box";
import OurWork from "../../components/OurWork";
import Navbar from "../../components/Navbar";

import "../../styles/ourWork.css";

const OurWorkPage = () => {
    useEffect(() => {
        Aos.init()
    }, [])

    return (
        <div
            data-aos="fade-up"
            data-aos-offset="100"
            className="our-work-container">
            <Navbar />

            <Box
                className="our-work"
                style={{
                    position: "relative",
                    top: "100px",
                //background: "white",
                opacity: "1",
                padding: 12,
                borderRadius: "20px",
                width: "100%",
                textAlign: "center",
                margin: "auto"
            }}>
                <OurWork />
            </Box>
        </div>
    )
}

export default OurWorkPage;