import { useState, useEffect, useCallback } from "react";
import Aos from "aos"
import "aos/dist/aos.css"

import {
    Container,
    Box,
    Typography,
    TextField,
    InputLabel,
    Grid,
    Button,
    FormControl,
    Alert
} from "@mui/material";
import Navbar from "../../components/Navbar";
import { Link, useNavigate } from "react-router-dom"
import { useForm, Controller } from "react-hook-form";
import Snackbar from '@mui/material/Snackbar';
import firebase from "../../firebase";

import "../../styles/auth.css";

const styles = {
    input: {
        fontSize: 36,
        padding: 3,
        margin: 1,
    },
}
const defaultValues = {
    email: "",
    password: ""
}

const SignUpPage = () => {
    const { control, handleSubmit } = useForm({ defaultValues });
    const [open, setOpen] = useState(false)
    const [error, setError] = useState("");
    const navigation = useNavigate()
    
    const onSubmit = useCallback(
        async (data) => {
            const { email, password } = data
            try {
                const res = await firebase.auth.createUserWithEmailAndPassword(email, password);

                if (res?.user.uid) {
                    navigation("/signIn")
                }
            } catch (error) {
                setOpen(true)
                setError(error.message)
            }
        },
        []
    )

    useEffect(() => {
        Aos.init()
    }, [])

    return (
        <div
            data-aos="fade-up"
            data-aos-offset="100"
            maxWidth className="admin-container">
            <Navbar />
            <Box className="auth-form">
                <Box className="auth-box">
                    <Typography className="text-center" variant="h2">Sign Up</Typography>

                    <form className="form" onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Controller
                            className="row"
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    sx={styles}
                                    label="Email"
                                    type="text"
                                    variant="filled"
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                />
                            )}
                            rules={{ required: 'Email is required' }}
                        />

                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    sx={styles}
                                    label="Password"
                                    type="password"
                                    variant="filled"
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                />
                            )}
                            rules={{ required: 'Password is required' }}
                        />

                        <Box className="row">
                            <Button type="submit" style={{ background: " #1D95C9", color: "white" }}>Sign up</Button>
                            <Box>

                                <Link style={{
                                    margin: "0 8px",
                                    textDecoration: "underline"
                                }} to="/signIn">
                                     Already have an account?
                                </Link>
                            </Box>
                        </Box>

                        <Snackbar open={open} autoHideDuration={6000}>
                            <Alert severity="error">{error}</Alert>
                        </Snackbar>
                    </form>
                </Box>
            </Box>
        </div>
    )
}

export default SignUpPage