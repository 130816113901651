import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Typography,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import VideoService from "../../services/videoService";

const VideoList = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      const responseVideos = await VideoService.getAll();
      console.log('responseVideos', responseVideos)
      setVideos(responseVideos);
    };
    fetchVideos();
  }, []);

  const handleEdit = (videoName) => {
    console.log(`Edit video: ${videoName}`);
  };

  const handleDelete = async (id) => {
    try {
        await VideoService.remove(id)
        window.location.reload(false);
    } catch (error) {
        console.log('error', error)
    }
  };

  return (
    <Container sx={{marginTop: 10}}>
      <Typography variant="h4" gutterBottom>
        Списък с видеа
      </Typography>

      <Link to="/administration/video/new">
        <Button variant="contained">Add Video</Button>
      </Link>

      <Grid container spacing={2}>
        {videos.map((video) => (
          <Grid key={video} item xs={12} sm={6} md={4} lg={4}>
            <Card style={{ marginBottom: "16px" }}>
              <CardContent>
                <video
                  controls
                  style={{ width: "100%", height: "200px" }}
                  src={video.url}
                  type="video/mp4"
                />
              </CardContent>
              <CardActions>
                {/* <Button
                  size="small"
                  color="primary"
                  onClick={() => handleEdit(video)}
                >
                  Edit
                </Button> */}
                <Button
                  size="small"
                  color="secondary"
                  onClick={() => handleDelete(video.id)}
                >
                  Delete
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default VideoList;
