import { Box, Grid } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LeftAside from "../../components/LeftAside";
import "../../styles/admin.css";
import Layout from "../Layout";
import HomePage from "./Dashboard";
import { Outlet } from "react-router-dom";

const Admin = () => {
  return (
    <Grid>
      {/* LeftAside */}
      
      <Grid>
        <LeftAside />
      </Grid>

      <Outlet />
      {/* <Grid>
        <Outlet />
      </Grid> */}
    </Grid>
  );
};

export default Admin;
