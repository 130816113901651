import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";

import '../styles/about.css'

const AboutUs = ({ aos, offset }) => {
  return (
    <Container
      data-aos={aos}
      data-aos-offset={offset}
      maxWidth="md" sx={{
      marginTop: 5,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    }}>
        <Box>
          <Typography
            variant="h3"
            sx={{color: 'orange'}}
            >About us</Typography>
        
            <Typography
              variant="subtitle1"
              sx={{maxWidth: '30em', margin: '14px 0'}}
              >
             We are a company with more than 20 years of experience in the construction of bathrooms and installations. Loyalty and accurate execution of projects is essential both for our customers and for ourselves. Our work with leading suppliers on the market allows us to realize our projects from scratch to perfection without a problem. Quality is a leading factor in our services, that's why our customers are always satisfied!
            </Typography>
        </Box>

        <Box className='box' style={{
          background: 'rgb(247 153 13)',
          borderRadius: '50%',
          width: '25%',
          maxHeight: '40%',
          height: '184px',
        }}>
          <Box style={{
            background: 'rgb(3, 6, 73)',
            borderRadius: '50%',
            width: '94%',
            maxHeight: '94%',
            height: '32em',
            margin: 'auto',
            position: 'relative',
            top: '-3.1em'
          }}>
            <Typography
              style={{
                color: 'orange',
                margin: '55px 50px',
                fontSize: '20px',
                maxWidth: '30em',
                width: '90%',
                position: 'relative',
                top: '2.5em'
              }}>22 years of experience</Typography>
          </Box>
        </Box>


    </Container>
  )
}

export default AboutUs