import { useState, useEffect } from "react";
import UploadIcon from '@mui/icons-material/Upload';
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";

const ImageUpload = ({ image, setImage }) => {
    
    useEffect(() => {
        setImage(image)
    }, [image])

    const handelChangeImage = (e) => {
        setImage(e.target.files[0])
    }
    return (
        <div>
            <Button
                variant="contained"
                component="label"
            >
                <UploadIcon />
                <input
                    type="file"
                    hidden
                    onChange={handelChangeImage}
                />
            </Button>

            {image ? (
                <div style={{ marginTop: 16 }}>
                    <img src={URL.createObjectURL(image)} width={150} height={130} alt="upload-image" />
                </div>
            ) : (
                <Skeleton sx={{ mt: 3 }} variant="rectangular" width={210} height={118} />
            )}
        </div>
    )
}

export default ImageUpload;