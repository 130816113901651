import { Container, Grid } from '@mui/material'
import BathroomWithTiles from '../videos/bathroom-with-tiles.mp4';
import Tiles from '../videos/tiles.mp4';
import BathroomWithSink from '../videos/bathroom-with-sink.mp4';
import BathroomToiletWithSink from '../videos/bathroom-toilet-with-sink.mp4';

const Videos = () => {
    return (
        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <video controls style={{ width: '100%', height: 'auto' }} src={BathroomWithTiles} type="video/mp4" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <video controls style={{ width: '100%', height: 'auto' }} src={Tiles} type="video/mp4" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <video controls style={{ width: '100%', height: 'auto' }} src={BathroomWithSink} type="video/mp4" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <video controls style={{ width: '100%', height: 'auto' }} src={BathroomToiletWithSink} type="video/mp4" />
                </Grid>
            </Grid>
        </Container>
    )
}

export default Videos;
