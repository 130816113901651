import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const isMobile = useMediaQuery('(max-width:600px)'); // или друг breakpoint, ако е необходимо

  return (
    <Grid container alignItems="center" style={{ backgroundColor: '#262060', padding: 15 }} justifyContent="space-between">
      <Grid item>
        <Link to="/">
          <Typography align="center" color="white" variant="h5">BathroomBuild</Typography>
        </Link>
      </Grid>
      <Grid item>
        {isMobile ? (
          <IconButton sx={{ color: 'white' }} onClick={handleMenuOpen}>
            <MenuIcon />
          </IconButton>
        ) : (
          <List
            sx={{
              display: 'flex',
              gap: '10px',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0',
              width: '100%',
            }}
          >
            <ListItem sx={{ color: 'white', flex: '1', minWidth: '10%', textAlign: 'center' }} component={Link} to="/home">
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem sx={{ color: 'white', flex: '1', minWidth: '30%', textAlign: 'center' }} component={Link} to="/gallery">
              <ListItemText sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }} primary="Gallery our jobs" />
            </ListItem>
            <ListItem sx={{ color: 'white', flex: '1', minWidth: '10%', textAlign: 'center' }} component={Link} to="/reviews">
              <ListItemText primary="Reviews" />
            </ListItem>
            <ListItem sx={{ color: 'white', flex: '1', minWidth: '25%', textAlign: 'center' }} component={Link} to="/contact">
              <ListItemText primary="Contact Us" />
            </ListItem>
            <ListItem sx={{ color: 'white', flex: '1', minWidth: '10%', textAlign: 'center' }} component={Link} to="/signIn">
              <ListItemText primary="Login" />
            </ListItem>
          </List>

        )}
      </Grid>

      <Drawer anchor="right" open={menuOpen} onClose={handleMenuClose}>
        <IconButton onClick={handleMenuClose}>
          <CloseIcon />
        </IconButton>
        <List>
          <ListItem button component={Link} to="/home">
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button component={Link} to="/gallery">
            <ListItemText primary="Gallery our jobs" />
          </ListItem>
          <ListItem button component={Link} to="/reviews">
            <ListItemText primary="Reviews" />
          </ListItem>
          <ListItem button component={Link} to="/contact">
            <ListItemText primary="Contact Us" />
          </ListItem>
          <ListItem button component={Link} to="/signIn">
            <ListItemText primary="Login" />
          </ListItem>
        </List>
      </Drawer>
    </Grid>
  );
};

export default Navbar;
