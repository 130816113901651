import CheckIcon from '@mui/icons-material/Check';
import '../styles/main.css'

const TitleCompany = () => {
  return (
    <div className='title-center'>
      customize your bathroom
    </div>
  )
}

export default TitleCompany