import "../styles/search.css"

import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import CategoryService from "../services/categoryService";

const Search = () => {
    const ref = useRef(null)
    const [showMenu, setShowMenu] = useState(false)
    const [categories, setCategories] = useState([])

    useEffect(() => {
        CategoryService.getAll()
            .then((res) => {
                setCategories(res)
            })
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [showMenu])
    return (
        <div className="container">
            <p className="type">Type bathroom</p>
            <input className="select" onClick={() => setShowMenu(true)} placeholder="Select &#11167;" />

            {showMenu && (
                <div ref={ref} className="select-menu">
                    {categories && categories.map((c) => {
                        const link = c.category.split(" ").join("&");
                        return (
                            <div key={c.category} className="item">
                                <Link to={`/bathrooms/type/${link}`}>{c.category}</Link>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default Search;