import { useEffect, useState } from 'react';
import { Container, Card, CardContent, Typography, Grid } from '@mui/material';

import ReviewService from '../services/reviewService';

const Reviews = () => {
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        const getReviews = async () => {
            const response = await ReviewService.getAll();
            setReviews(response);
        };

        getReviews();
    }, []);

    return (
        <Container sx={{ marginTop: 5 }}>
            <Grid container spacing={2}>
                {reviews.map((review) => (
                    <Grid item key={review.id} xs={12} sm={6} md={4} lg={3}>
                        <Card sx={{ marginBottom: 5, height: '100%' }}>
                            <Typography variant='h4' align='center'>{review.username}</Typography>
                            <CardContent>{review.message}</CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default Reviews;
